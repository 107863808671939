export const userDefaultState = {
  id: null,
  username: "",
  email: "",
  first_name: "",
  last_name: "",
  birthdate: null,
  street_number: "",
  street_type: "",
  street_name: "",
  city_zipcode: "",
  city: "",
  address_complement: "",
  country: "",
  phone_number: "",
};
